export const Values = [
    {
        title: "Innovation",
        description:
            "We’re constantly exploring new technologies and pushing the boundaries of what’s possible in software development.",
        image: 'innovation.png',
        altField: 'innovation-image'
    },
    {
        title: 'Reliability',
        description: 'We pride ourselves on delivering dependable solutions that meet the highest standards of quality and performance.',
        image:'security.png',
        altField: 'reliability-image'
    },
    {
        title: 'Client-Centered',
        description: 'Our clients are at the heart of everything we do. We prioritize open communication, collaboration, and delivering solutions that align with their business goals.',
        image: 'user-avatar.png',
        altField: 'client-image'
    },
    {
        title: 'Optimization',
        description: 'We’re always learning, evolving, and improving our processes to stay ahead in a fast-moving industry.',
        image:'optimization.png',
        altField: 'optimization-image'
    }
];

export const Specialties = [
    {
        title: "Web Development",
        description:
            "Creating dynamic, responsive, and user-friendly websites.",
    },
    {
        title: 'Mobile App Development',
        description: 'Designing innovative mobile solutions for iOS and Android.'
    },
    {
        title: 'Cloud Solutions',
        description: 'Helping businesses transition to the cloud for scalability and efficiency.'
    },
    {
        title: 'Software Consulting',
        description: 'Providing expert advice and solutions that fit your business needs.'
    }
]

export const process = [
    {
        title: "Discovery",
        description:
            "We start by understanding your business and its unique needs."
    },
    {
        title: 'Design',
        description: 'Our designers craft user-centric interfaces and experiences.'
    },
    {
        title: 'Development',
        description: 'Rigorous testing ensures your software is reliable, secure, and efficient.'
    },
    {
        title: 'Testing',
        description: 'Providing expert advice and solutions that fit your business needs.'
    },
    {
        title:'Launch & Support',
        description:'After launch, we provide continuous support to ensure long-term success.'
    }

]

export const specialtyFields = [
    {
        title: 'Web Development',
        image: '4707122.jpg'
    },
    {
        title: 'Mobile Development',
        image: 'mobileDev.jpg'
    },
    {
        title: 'Cloud Solutions',
        image: '6551283.jpg'
    },
    {
        title: 'Software Consulting',
        image: '4022164.jpg'
    },
]