import styles from './cstyles.module.css';

const LandingImage = (props) => {
    return (
            <div className={styles.itImageBorder}>
                <div className={styles.homeImageWrapper}>
                    <img
                        className={styles.itImage}
                        src={props.image}
                        alt={props.altProp}
                    />
                </div>
            </div>
    )
}
export default LandingImage;