import Header from "../../components/header";
import AboutStory from "./aboutStory";
import AboutHero from "./aboutHero";
import AboutValues from "./aboutValues";
import OurProcess from "./process";
import Specialties from "./specialties";
import styles from './myabout.module.css';
import Footer from "../../components/footer";

const AboutPage = ()=>{
    return (
        <div className={styles.aboutBigContainer}>
            <Header />
            <AboutHero 
            title='ABOUT US'
            text='Driving Innovation Through Tailored Software Solutions'
            />
            <AboutStory />
            <AboutValues />
            <Specialties />
            <OurProcess />
            <Footer />
        </div>
    )
}

export default AboutPage;