import styles from './header.module.css';
import { SiTicktick } from "react-icons/si";
import ScrollAnimation from 'react-animate-on-scroll';


const Expertise = () => {
    const list1 = ['responsive', 'Fast & Secure', 'GlobalDesign', 'Drag & Drop', 'Full Dynamic', 'Support']
    const list2 = ['iOS & Android', 'Fast & Secure', 'Global Design']
    return (
        <div className={styles.servicesContainer}>
            <div className={styles.devBigContainer}>
                <div className={`${styles.devServices} ${styles.devFirstChild}`}>
                    <div className={styles.devImageContainer}>
                        <ScrollAnimation
                            animateIn='bounceInRight'
                            animateOut='bounceOutLeft'
                            animateOnce='true'
                        >
                            <img className={styles.devImage} src='laptopImage.jpg' alt='laptop-img' />

                        </ScrollAnimation>
                    </div>
                    <div className={styles.devDescContainer}>
                        <ScrollAnimation
                            animateIn='fadeIn'
                            animateOnce='true'>
                            <h2>
                                WEBSITE DESIGN
                            </h2>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn='bounceInUp'
                            animateOut='bounceOutDown'
                            animateOnce='true'
                        >
                            <p>
                                At Codesmash, we believe that great design is at the heart of an exceptional user experience. Our website combines a sleek, modern aesthetic with intuitive navigation, ensuring that visitors can easily find the information they need. With responsive layouts that adapt seamlessly to any device, we prioritize both functionality and visual appeal, creating an engaging environment that reflects our commitment to innovation and user satisfaction. Explore our services and discover how we can elevate your digital experience.
                            </p>
                        </ScrollAnimation>

                        <div className={styles.devListGrid}>
                            {list1.map((l) => {
                                return (
                                    <ScrollAnimation
                                        animateIn='fadeIn'
                                        animateOnce='true'
                                    >
                                        <div className={styles.devGridItems}>
                                            <SiTicktick className={styles.siticktick} />
                                            <p>{l}</p>
                                        </div>
                                    </ScrollAnimation>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={`${styles.devServices} ${styles.devMobile}`}>
                    <div className={styles.devImageContainer}>
                        <ScrollAnimation
                            animateIn='bounceInLeft'
                            animateOut='bounceOutRight'
                            animateOnce='true'
                        >
                            <img className={styles.devImage} src='appDevelopment.jpg' alt='app-development' />
                        </ScrollAnimation>
                    </div>
                    <div className={styles.devDescContainer}>
                        <ScrollAnimation
                            animateIn='fadeIn'
                            animateOnce='true'>
                            <h2>
                                APP DEVELOPMENT
                            </h2>
                        </ScrollAnimation>
                        <ScrollAnimation
                            animateIn='bounceInUp'
                            animateOut='bounceOutDown'
                            animateOnce='true'
                        >
                            <p>
                                we specialize in crafting innovative and user-friendly mobile applications that bring your ideas to life. Our expert team combines cutting-edge technology with a deep understanding of user behavior to develop apps that are not only functional but also engaging. Whether you need a native solution for iOS and Android or a cross-platform application, we tailor our approach to meet your unique goals. From concept to launch, we’re dedicated to delivering high-quality apps that enhance user experiences and drive your business forward. Let us help you turn your vision into reality                    </p>
                        </ScrollAnimation>

                        <div className={styles.devListGrid}>
                            {list2.map((l) => {
                                return (
                                    <ScrollAnimation
                                        animateIn='fadeIn'
                                        animateOnce='true'
                                    >
                                        <div className={styles.devGridItems}>

                                            <SiTicktick className={styles.siticktick} />
                                            <p>{l}</p>

                                        </div>
                                    </ScrollAnimation>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Expertise;