import { Values } from "./values";
import styles from "./myabout.module.css";
import ScrollAnimation from "react-animate-on-scroll";

const AboutValues = () => {
  return (
    <div className={`${styles.aboutValuesContainer} ${styles.values}`}>
      <div className={styles.aboutValuesBigBox}>
        <div>
          <div className={`${styles.aboutValuesTitleBox} ${styles.valueBox}`}>
            <ScrollAnimation
              animateIn='fadeIn'
              animateOnce='true'>
              <h2>OUR VALUES</h2>
            </ScrollAnimation>
          </div>
          <div className={styles.aboutGridContainer}>
            <ScrollAnimation
              animateIn='bounceInLeft'
              animateOut='bounceInRight'
              animateOnce='true'>
              <div className={styles.aboutValSection}>
                {Values.slice(0, 2).map((value, index) => {
                  return <div className={styles.descSection} key={index}>
                    <div className={styles.descImageBox}>
                      <img className={styles.descImages} src={value.image} alt="" />
                    </div>
                    <h2>{value.title}</h2>
                    <p>{value.description}</p>
                  </div>

                })}
              </div>
            </ScrollAnimation>
            <div className={`${styles.aboutValSection} ${styles.codeImgSection}`}>
                <img src="aboutImage.jpg" alt="about-image" />
            </div>
            <ScrollAnimation
              animateIn='bounceInRight'
              animateOut='bounceInLeft'
              animateOnce='true'>
              <div className={styles.aboutValSection}>
                {Values.slice(2).map((value, index) => {
                  return <div className={styles.descSection} key={index}>
                    <div className={styles.descImageBox}>
                      <img className={styles.descImages} src={value.image} alt="" />
                    </div>
                    <h2>{value.title}</h2>
                    <p>{value.description}</p>
                  </div>
                })}
              </div>
            </ScrollAnimation>

          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutValues;
