import { pricingList } from "./catDef";
import GreenHeader from "../../components/greenHeader";
import styles from './cService.module.css';
import { FaPlus } from "react-icons/fa6";
import ScrollAnimation from "react-animate-on-scroll";


const Pricing = () => {
  return (
    <div className={`${styles.serviceContainer} ${styles.assuarance} ${styles.priceInfo}`}>
      <div className={`${styles.serviceBigBox}`}>
        <div className={styles.serviceImagesBox}>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOnce='true'>
            <GreenHeader text='our packages' />
          </ScrollAnimation>

        </div>
        <ScrollAnimation
          animateIn='bounceInUp'
          animateOut='bounceInDown'
          animateOnce='true'>
          <div className={styles.variationsBox}>
            {pricingList.map((list) => {
              return (

                <div className={styles.pricingBox}>
                  <div className={styles.packageListing}>
                    <p>{list.title}</p>
                  </div>
                  <div className={styles.priceDescWrapper}>
                    {list.features.map((f) => {
                      return (
                        <p>{f}</p>
                      )
                    })}
                  </div>
                  <h3 className={styles.priceHeader}>{list.price}</h3>
                    <a className={styles.faPlusref} href="/contact-us">
                    <button>
                    <FaPlus />
                    Order Now
                  </button>
                    </a>
                </div>

              )
            })}
          </div>
        </ScrollAnimation>

      </div>
    </div>
  );
};
export default Pricing;
