export const serviceCategories = [
  {
    title: "Web Development",
    description:
      "We build modern, responsive websites tailored to your business goals. From stunning landing pages to full-scale e-commerce platforms, we ensure your digital presence stands out and performs flawlessly.",
    image: "webdevdesc.webp",
    alt: "webdevdesc",
    services: [
      "Custom Design",
      "Responsive Layouts",
      "E-commerce Solutions",
      "Performance Optimization"
    ],
  },
  {
    title: "Mobile App Development",
    description:
      "Reach your customers on the go with powerful iOS and Android applications. Whether you need a customer-facing app or an internal business tool, our solutions are designed for performance and scalability.",
    image: "mobileDevDesc.webp",
    alt: "mobileDevDesc",
    services: [
      "Cross-Platform Apps",
      "User-Centered Design",
      "API Integrations",
      "Push Notifications"
    ],
  },
  {
    title: "Software Consulting",
    description:
      "Need expert guidance? Our consulting services help you define your technology needs, plan robust system architectures, and deploy the right tools for your business growth.",
    image: "softwareConsultingDesc.jpeg",
    alt: "softwareConsultingDesc",
    services: [
      "Business Process Analysis",
      "Technical Roadmaps",
      "System Architecture Design",
      "Tech Stack Recommendations"
    ],
  },
  {
    title: "Cloud Solutions",
    description:
      "Move your business to the cloud with confidence. We offer cloud setup, migration, and management services that improve efficiency, reduce costs, and scale effortlessly.",
    image: "cloudSolutionsDesc.png",
    alt: "cloudSolutionsDesc",
    services: [
      "Cloud Migration",
      "Infrastructure Setup",
      "Data Backup & Recovery",
      "Cloud-Based Applications"
    ],
  },
];

export const assureObjects = [
  {
    title: "Expert Team",
    description:
      "Our team consists of skilled developers, designers, and consultants with years of experience delivering successful software solutions",
    image: 'quality.png',
  },
  {
    title: "Scalable Solutions",
    description:
      "We ensure every solution we build is flexible and designed to grow alongside your business",
    image:'resize.png',
  },
  {
    title: "Client-Centered Approach",
    description:
      "We work closely with you to understand your business needs and deliver tailored solutions that drive results",
    image:'target.png'
  },
  {
    title: "Comprehensive Support",
    description:
      "Our relationship doesn’t end at project delivery. We offer ongoing support and maintenance to ensure your software continues to perform at its best",
    image:'customer-service.png',
  },
  {
    title: "Timely Delivery",
    description:
      "We pride ourselves on meeting deadlines without compromising on quality, so you can trust us to deliver results on time, every time",
    image:'effective.png',
  },
];

export const pricingList = [
  {
    title: "Basic",
    features: [
      "Design up to 5 Pages",
      "Mobile Responsive Design",
      "Basic SEO Setup",
      "Standard Email Support",
      "Hosting & Domain Setup"
    ],
    price: "60,000",
  },
  {
    title: "Popular",
    features: [
      "Design up to 10 Pages",
      "CMS Integration",
      "Advanced SEO",
      "E-commerce Setup (up to 10 products)",
      "Monthly Updates",
      "Priority Support (24-hour)"
    ],
    price: "80,000",
  },
  {
    title: "Professional",
    features: [
      "Custom Web Applications",
      "API Integrations",
      "Full E-commerce Platform",
      "Ongoing Maintenance",
      "Comprehensive Support (12-hour)",
      "Business Strategy Consulting"
    ],
    price: "Contact for pricing",
  },
];


export const askedQs = [
  {
    question: "How long does it take to build a custom website?",
    answer:
      "Depending on the complexity, most websites take between 4-8 weeks to complete.",
  },
  {
    question: "Do you offer ongoing support after development?",
    answer:
      "Yes, we offer a range of maintenance and support packages to keep your software up-to-date and secure.",
  },
  {
    question: "What industries do you specialize in?",
    answer:
      "We work with businesses across various industries, including retail, healthcare, finance, and logistics.",
  },
];
