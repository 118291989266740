import { assureObjects } from "./catDef";
import GreenHeader from "../../components/greenHeader";
import styles from './cService.module.css';
import ScrollAnimation from "react-animate-on-scroll";

const Assure = () => {
  const whychooseusParagraph = 'At CodeSmash, we combine innovation, expertise, and a client-first approach to deliver custom software solutions that meet your unique business needs. Our team of skilled developers and consultants work closely with you to ensure that each project is crafted to perfection, from website development to cutting-edge tech solutions. With a focus on reliability, efficiency, and quality, we don’t just build software—we create lasting partnerships that help your business thrive in an ever-evolving digital landscape. Choose us to experience tailored solutions that drive growth and success.'
  return (
    <div className={`${styles.serviceContainer} ${styles.assuarance}`}>
      <div className={`${styles.serviceBigBox} ${styles.assureContainer}`}>
        <div className={`${styles.assureBoxes}`}>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOnce='true'>
            <div>
              <img className={styles.assureImage} src='whyChooseUs.jpg' alt='why choose us' />
            </div>
          </ScrollAnimation>
          <ScrollAnimation
            animateIn='fadeIn'
            animateOnce='true'>
            <div className={styles.wcu}>
              <GreenHeader text='Why choose us?' />
              <p>{whychooseusParagraph}</p>
            </div>
          </ScrollAnimation>

        </div>

        <div className={`${styles.assureBoxes} ${styles.assureListBigBox}`}>
          <ScrollAnimation
            animateIn='bounceInUp'
            animateOut='bounceInDown'
            animateOnce='true'>
            <div className={styles.assureListWrapper}>
              {assureObjects.map((obj) => {
                return (
                  <div className={styles.wrapperLists}>
                    <div className={styles.wrapperListsInfo}>
                      <img className={styles.assureListImage} src={obj.image} alt="" />
                    </div>
                    <div className={styles.wrapperListsInfo}>
                      <h3>{obj.title}</h3>
                      <p>
                        {obj.description}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>

          </ScrollAnimation>

        </div>
      </div>
    </div>
  );
};

export default Assure;
