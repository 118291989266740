import styles from './myabout.module.css';

const AboutHero = (props) => {
    return (
        <div className={styles.aboutHeroContainer}>
            <div className={styles.aboutHeroBox}>
                <h1>{props.title}</h1>
            </div>
            <div className={styles.aboutHeroBox}>
                <p>
                    {props.text}
                </p>
            </div>
        </div>
    )
}

export default AboutHero;  