import styles from './header.module.css';
import { IoPaperPlaneOutline } from "react-icons/io5";
import ScrollAnimation from 'react-animate-on-scroll';


const Story = () => {
    return (
        <div className={styles.blogContainer}>
            <div className={styles.blogExtension}>
                <div className={styles.halfBox}>
                    <ScrollAnimation
                        animateIn='bounceInLeft'
                        animateOut='bounceOutRight'
                        animateOnce='true'>
                        <div>
                            <IoPaperPlaneOutline className={styles.papericonstyle} />
                        </div>
                    </ScrollAnimation>

                    <div className={styles.blogTitleBox}>
                        <ScrollAnimation
                            animateIn='fadeIn'
                            animateOnce='true'
                        >
                            <h2>
                                <span className={styles.insights}>Inside the Code:</span> <br />Insights from CodeSmash
                            </h2>
                        </ScrollAnimation>

                    </div>

                    <div className={styles.blogTextBox}>
                    <ScrollAnimation
                        animateIn='bounceInRight'
                        animateOut='bounceOutLeft'
                        animateOnce='true'>
                        <p>
                        Explore innovative software solutions and groundbreaking ideas at CodeSmash. Our commitment to transforming businesses through technology drives us to deliver tailored, cutting-edge strategies that meet your unique needs. With a focus on collaboration and technical excellence, we bring a fresh perspective to software development, helping you achieve seamless, scalable solutions. Embrace CodeSmash’s expertise and take the next step in your transformative journey toward technology-driven success.                    </p>
                        </ScrollAnimation>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default Story;