import { process } from "./values";
import styles from "./myabout.module.css";
import { FaCheck } from "react-icons/fa";
import BlueButton from "../../components/blueButton";

const OurProcess = () => {
  return (
    <div className={`${styles.aboutValuesContainer} ${styles.processFlow}`}>
      <div className={`${styles.aboutValuesBigBox} ${styles.processBigBox}`}>
        <div className={styles.processBox}>
          <div className={` ${styles.approachBox}`}>
            <h2>OUR APPROACH</h2>
          </div>
          <p>
            We follow a well-defined process to ensure every project is a success
          </p>
          {process.map((p) => {
            return (
              <div className={styles.processList}>
                <div className={styles.processItemWrapper}>
                <FaCheck style={{
                  color: '#00deff',
                  width: '1rem',
                  height: '1rem',
                }} />
                <p>{p.title}</p>
                </div>
                
              </div>
            );
          })}
        </div>
        <div className={styles.processBox}>
          <div className={styles.processBox}>
            <h2>GET IN TOUCH</h2>
          </div>
          <p>At CodeSmash, we’re always excited to hear about new projects and challenges. Whether you need a new website, a mobile app, or a custom software solution, we’d love to help you bring your vision to life.</p>
          <div className={styles.processButtonBox}>
          <a href="/contact-us">
          <BlueButton text='Contact us' />
          </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurProcess;
