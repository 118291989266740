import styles from './cstyles.module.css';
import { useState } from 'react';
import { GiHamburgerMenu } from "react-icons/gi";
import { IoClose } from "react-icons/io5";


const Header = () => {
    const [isOpen, setIsOpen] = useState(false);


    return (
        <div className={styles.headerContainer}>
            <header>
                <div className={styles.logoWrapper}>
                    <p>Code<span className={styles.logoSpan}>Smash</span></p>
                </div>
                <button 
                    onClick={() => setIsOpen(!isOpen)}
                    className={styles.hamburgerButton}>
                    {!isOpen && <GiHamburgerMenu />}
                    {isOpen && <IoClose />}
                </button>
                <div className={styles.largeViewBox}>
                            <ul className={styles.navlinks}>
                                <li><a href='/'>Home</a></li>
                                <li><a href='about'>About</a></li>
                                <li><a href='blog'>Blog</a></li>
                                <li><a href='service'>Services</a></li>
                                <li><a href='contact-us'>Contact Us</a></li>
                            </ul>
                        </div>

                {
                    isOpen && (
                        <div className={styles.smallViewBox}>
                            <div className={`${styles.navlinks} ${styles.mobileLinks}`}>
                                <li><a href='/'>Home</a></li>
                                <li><a href='about'>About</a></li>
                                <li><a href='blog'>Blog</a></li>
                                <li><a href='service'>Services</a></li>
                                <li><a href='contact-us'>Contact Us</a></li>
                            </div>
                        </div>
                    )
                }

            </header>
        </div>)
}

export default Header;