import styles from "./cstyles.module.css";

const GreenHeader = (props) => {
  return (
    <div className={styles.greenBigBox}>
      {props.image ? (
        <>
          <div className={styles.greenWrapper}>
            <img
              className={styles.greenImage}
              src={props.image}
              alt={props.altProp}
            />
          </div>
          <div className={styles.greenWrapper}>
            <h2>{props.text}</h2>
          </div>
        </>
      ) : (
        <div className={styles.greenWrapper}>
          <h2>{props.text}</h2>
        </div>
      )}
    </div>
  );
};

export default GreenHeader;
