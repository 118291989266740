import Header from "../../components/header";
import AboutHero from "../about/aboutHero";
import BlogList from "./blogList";

const BlogPage = ()=>{
    return(
        <div>
            <Header />
            <AboutHero
                title='OUR BLOG'
                text='Insights & Innovations: The CodeSmash Blog'
             />
             <BlogList />
        </div>
    )
}

export default BlogPage;