import styles from "./header.module.css";
import Button from "../../components/button";
import LandingImage from "../../components/landingImage";

const Home = () => {
  return (
    <div className={styles.homeContainer}>
      <div className={styles.homePageBox}>
        <div className={styles.homeboxContent}>
          <div className={styles.homeContentWrapper}>
            <div className={styles.hometitleBox}>
              <h2>Unlock Innovative Software Solutions with CodeSmash</h2>
            </div>
            <div className={`${styles.sInfoList} ${styles.homeP}`}>
              <p>
                At CodeSmash, we are passionate about delivering cutting-edge
                software solutions tailored to your business needs. With a focus
                on innovation and reliability, we collaborate closely with you
                to turn your ideas into efficient software solutions. Let's
                smash the code and solve your challenges.
              </p>
            </div>
            <div className={styles.homeContactBox}>
              <a href="/contact-us">
              <Button text="CONTACT US" />
              </a>
            </div>
          </div>
        </div>
        <div className={`${styles.homeboxContent} ${styles.animateLandingPage}`}>
          <LandingImage image='ITsolutions.jpg' altProp='home-image' />
        </div>
      </div>
    </div>
  );
};
export default Home;
