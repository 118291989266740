import styles from './header.module.css';
import Header from '../../components/header';
import Home from "./home";
import About from "./overview";
import Blog from "./story";
import Services from "./expertise";
import Footer from '../../components/footer';

const HomePage = ()=>{
    return(
        <div className={styles.appContainer}>
        <Header />
        <div className={styles.bodyBox}>
          <Home />
          <About />
          <Blog />
          <Services />
          <Footer />
        </div>
      </div>
    )

}

export default HomePage;