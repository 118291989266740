import styles from './contact.module.css';
import { qs } from "./contactQs";
import axios from 'axios';
import { useState, useEffect } from 'react';
import { ColorRing } from 'react-loader-spinner'

const ContactForm = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [clientRequest, setClientRequest] = useState('');
    const [success, setSuccess] = useState(false);
    const [loading, setLoading] = useState(false);
    const [failure, setFailure] = useState(false)

    const handleSubmit = () => {
        setLoading(true);
        axios.post(
            'https://codesmashapi.onrender.com/submissions/information',
            {
                name: name,
                email: email,
                request: clientRequest
            },
            
        ).then((response) => {
            if (response.status === 201) {
                setSuccess(true);
            }

        }).catch((error) => {
            setFailure(true);
        }).finally(()=>{
            setLoading(false)
        })
    }

    useEffect(()=>{
        if(success || failure){
            const timer = setTimeout(() => {
                setFailure(false);
                setSuccess(false)
            }, 4000);
        
            return ()=>clearTimeout(timer);
        }
    }, [failure, success]);


    return (
        <div className={styles.cformWrapper}>
            <div className={styles.contactFormHeaderWrap}>
                {qs.map((s) => {
                    return (
                        <div className={`${styles.cInfoDesc} ${styles.otherdescBox} ${styles.otherdescBox1}`}>
                            <h3>
                                {s.q}
                            </h3>
                            <p>
                                {s.a}
                            </p>
                        </div>
                    )
                })}
            </div>
            <form className={styles.contactform}>
                <div className={`${styles.cformBox}`}>
                    {success && <p className={styles.successContent}>Successfully posted request!</p>}
                    {failure && <p className={styles.failureContent}>Uh Oh! Something went wrong!</p>}
                    <div className={styles.cFields}>
                        <label>Your Name</label>
                        <input onChange={(e) => setName(e.target.value)} type="text" required />
                    </div>
                    <div className={styles.cFields}>
                        <label>Email Address</label>
                        <input onChange={(e) => setEmail(e.target.value)} type="email" required />
                    </div>
                    <div className={styles.cFields}>
                        <label>What can we help with?</label>
                        <textarea onChange={(e) => { setClientRequest(e.target.value) }} type="text" required />
                    </div>
                    {loading && <ColorRing
                            visible={true}
                            height="40"
                            width="40"
                            ariaLabel="color-ring-loading"
                            wrapperStyle={{}}
                            wrapperClass="color-ring-wrapper"
                            colors={['#012b64', '#043c75', '#012b64', '#043c75', '#849b87']}
                        />}
                    {!loading && <div className={styles.submitButtonBox}>
                        <button type='button' onClick={handleSubmit}>
                        send message
                    </button>
                    </div>}
                </div>
            </form>
        </div>
    )
}
export default ContactForm;