import Header from '../../components/header';
import Assure from "./assure";
import Pricing from "./Pricing";
import FAQs from "./faq";
import OurServices from '../../components/ourservices';
import { serviceCategories } from './catDef';
import AboutHero from '../about/aboutHero';
import Footer from '../../components/footer';

const ServicePage = () => {
    return (
        <div>
            <Header />
            <AboutHero
                title='OUR SERVICES'
                text='Driving Innovation Through Tailored Software Solutions'

            />
            {
                serviceCategories.map((s, index) => {
                    return (
                        <OurServices
                            key={index}
                            index={index} 
                            heading={s.title}
                            description={s.description}
                            image={s.image}
                            alternateText={s.alt}
                            allservices={s.services}
                        />
                    );
                })
            }
            <Assure />
            <Pricing />
            <FAQs />
            <Footer />
        </div>
    )
}

export default ServicePage;