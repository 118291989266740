import styles from "./myabout.module.css";
import { specialtyFields } from "./values";
import ScrollAnimation from "react-animate-on-scroll";

const Specialties = () => {
  return (
    <div className={`${styles.aboutValuesContainer} ${styles.offeredFeatures}`}>
      <div className={styles.aboutValuesBigBox}>
        <div className={styles.aboutValuesTitleBox}>
          <h2>What we offer</h2>
        </div>
        <ScrollAnimation
              animateIn='fadeIn'
              animateOnce='true'>
        <div className={styles.specialtyBox}>
          {specialtyFields.map((field) => {
            return (
              <a className={styles.specialtyInfoBoxtag} href="/service">
              <div className={styles.specialtyInfoBox}>
                <div className={styles.InfoLearnMoreBox}>
                  <p>Learn More...</p>
                </div>
                <img
                  className={styles.specialtyImage}
                  src={field.image}
                  alt=""
                />
                <h3>{field.title}</h3>
              </div>
              </a>
            );
          })}
        </div>
              </ScrollAnimation>
      </div>
    </div>
  );
};
export default Specialties;
