import styles from '../pages/Home/header.module.css';

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <div className={styles.footerContainer}>
                <div className={styles.clinkWrapper}>
                    <p>&copy; {year} CodeSmash. All rights reserved.</p>
                </div>
        </div>
    )
}
export default Footer;