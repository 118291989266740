export const elements = {
    el1: {
        title: 'Efficient Coding',
        desc: 'Efficient and clean code is the backbone of every successful software project. At CodeSmash, we strive to write code that is functional and optimized for scalability and future growth. By adhering to best practices and leveraging modern programming languages, we deliver software that performs at peak efficiency, reducing bugs and improving maintainability. CodeSmash’s coding excellence ensures your project is built to evolve alongside your business needs.',
        image: 'code.png',
    },
    el2: {
        title: 'Seamless Deployment',
        desc: 'At CodeSmash, we emphasize collaboration every step of the way. Our seamless deployment process is designed to work in harmony with your existing infrastructure, minimizing downtime and ensuring a smooth transition. We work closely with your team to ensure that the deployment aligns with your business goals, providing support as needed. With CodeSmash, you can be rest assured that your software is deployed effectively, ready to empower your business to achieve more',
        image: 'start-up.png',
    },
    el3: {
        title: 'Precision Testing',
        desc: 'At CodeSmash, we believe that quality is non-negotiable. Our testing processes ensure that every software solution we develop is fine-tuned for performance, security, and stability. By using state-of-the-art testing frameworks, we identify and resolve potential issues before they can impact your business, ensuring a smooth user experience. Trust CodeSmash to deliver precision-tested solutions that stand the test of time.',
        image: 'testing.png',
    }
}


