import styles from './blogstyles.module.css';
import { blogs } from './blogs';
import { FaSearch } from "react-icons/fa";
import { useState } from 'react';

const BlogList = ()=>{
    const [searchField, setSearchField]=useState('');

    const filteredBlog = blogs.filter(b=>{
        return(
            (b.title.toLowerCase()).includes(searchField.toLowerCase()) ||
            (b.category.toLowerCase()).includes(searchField.toLowerCase())        )
    })

    const handleChange = e=>{
        setSearchField(e.target.value);
    }

    return(
        <div className={styles.blogListContainer}>
            <div className={styles.blogListBox}>
                <div className={styles.blogsWrapper}>
                    {filteredBlog.map((blog)=>{
                        return(
                            <div className={styles.blogPostBox}>
                                <div className={styles.postChildren}>
                                    <h2>{blog.title}</h2>
                                </div>
                                <div className={styles.postChildren}>
                                   <p>{blog.info}</p> 
                                </div>
                                <div className={styles.postChildren}>
                                    <p>category: <span className={styles.categorySpan}>{blog.category}</span></p>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={styles.blogsSearchBox}>
                    <input 
                        type='search' 
                        placeholder='search...'
                        onChange={handleChange}
                        />
                    <button className={styles.blogsSearchButton} type='button'>
                        <FaSearch className='faSearchIcon'/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BlogList;