import GreenHeader from "../../components/greenHeader";
import styles from './cService.module.css';

const ServiceCTA = () => {
    return (
        <div className={styles.serviceCTAContainer}>
            <div className={styles.ctaServiceBox}>
                <h2>
                    <GreenHeader text='Have a project in mind?' />
                </h2>
            </div>
            <div className={`${styles.ctaServiceBox} ${styles.haveProject}`}>
                <a href="/contact-us">
                    <button className={styles.serviceContactButton}>
                        CONTACT US
                    </button>
                </a>
            </div>
        </div>
    )
}
export default ServiceCTA;