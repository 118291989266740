import { askedQs } from "./catDef";
import ServiceCTA from "./serviceCTA";
import styles from './cService.module.css';
import { IoIosArrowDown } from "react-icons/io";
import { useState } from "react";


const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index)=>{
    setOpenIndex(openIndex === index?null:index);
  }

  return (
    <div div className={`${styles.serviceContainer} ${styles.faqsBigContainer}`}>
      <div className={`${styles.serviceBigBox} ${styles.serviceFooter}`}>
        <div className={styles.faqsBox}>
          <ServiceCTA />
        </div>
        <div className={styles.faqsBox}>
        <h3>FAQs</h3>
          {askedQs.map((q, index) => {
            const isOpen = openIndex === index;

            return (
              <div className={styles.qsWrapper}>
                <div className={styles.questionContainer}>
                  <p>{q.question}</p>
                  <button
                  onClick={()=>toggleAnswer(index)}
                  ><IoIosArrowDown /></button>
                </div>
                <div 
                className={`
                ${isOpen ? styles.showAnswer : styles.answerContainer}
                `}
                >
                  <p>{q.answer}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default FAQs;
