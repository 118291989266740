import GreenHeader from "../../components/greenHeader";
import LandingImage from "../../components/landingImage";
import styles from "./myabout.module.css";

const AboutStory = () => {
  const ourText =
    "Founded with a passion for technology and innovation, CodeSmash has been dedicated to helping businesses thrive in the digital landscape.Our journey began with a simple mission: to provide high - quality, scalable, and reliable software solutions that empower companies to reach new heights.Over the years, we’ve grown into a trusted partner for businesses across industries, known for our expertise in web development, mobile app solutions and cloud services.";

  return (
    <div className={styles.aboutStoryContainer}>
      <div className={styles.aboutStoryBox}>
        <div className={styles.aboutSectionWrapper}>
          <LandingImage image="treeimage.jpg" />
        </div>
        <div className={styles.aboutInfoWrapper}>
          <div className={styles.ourStoryTitleBox}>
            <GreenHeader text="Our Story" />
          </div>
          <div>
            <p>{ourText}</p>
          </div>
          <div className={styles.aboutLearnMore}>
            <p>
              <i>Learn more about our service or Explore our Work</i>
            </p>
            <a href="/service">
            <button className={styles.learnMoreButton}>
              <img className={styles.rightArrow} src="right-arrow.png" alt="right-button"/>
            </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AboutStory;
