import ContactForm from "./contactForm";
import Cinfo from "./cInfo";
import styles from './contact.module.css';

const Cblock = () => {
    return (
        <div className={styles.cInfoBigBox}>
            <div className={styles.cInfoBox}>
                <div className={`${styles.cInfoWrapper} ${styles.cInfoWrapper1}`}>
                    <Cinfo />
                </div>
                <div className={`${styles.cInfoWrapper}`}>
                    <ContactForm />
                </div>
            </div>
        </div>
    )
}

export default Cblock;