import styles from './cstyles.module.css';
import { FaCheck } from "react-icons/fa";

const OurServices = (props) => {
    const isEven = props.index % 2 === 0; 
    
    return (
        <div className={styles.serviceDescriptionContainer}>
            <div className={`${styles.serviceDescriptionBoxes} ${isEven ? styles.rowNormal : styles.rowReverse}`}>
                <div className={`${styles.serviceBigImageBox} ${styles.serviceBoxes}`}>
                    <img className={styles.bigServiceImage} src={props.image} alt={props.alternateText} />
                </div>
                <div className={`${styles.serviceTextBox} ${styles.serviceBoxes}`}>
                    <h2>{props.heading}</h2>
                    <p>{props.description}</p>
                    <div className={styles.featuresBox}>
                        <h4>Features</h4>
                        {props.allservices.map((s, idx) => (
                            <div key={idx} className={styles.featureDesc}>
                                <FaCheck className={styles.faCheckIcon} style={{ color: '#00deff', width: '10%' }} />
                                <p>{s}</p>
                            </div>
                        ))}
                        <div className={styles.featureButtonBigBox}></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OurServices;
