import styles from './contact.module.css';
import { SiGmail } from "react-icons/si";



const Cinfo = () => {
  const contactParagraph =
    "We’re here to help you bring your vision to life. Whether you’re looking to build a new website, create custom software, or need expert consultation, our team is ready to assist. We’d love to hear from you and discuss how we can turn your ideas into reality.";
  return (
    <div className={styles.cInfoDescBox}>
      <div className={styles.cInfoDesc}>
        <h3>
        GET IN TOUCH WITH <a href="/"><span className={styles.namespan}>CODESMASH</span></a>

        </h3>
        <p>{contactParagraph}</p>
      </div>
      <div>
        <div>
           <div className={styles.quickLinksContact}>
            <h3>Quick Links</h3>
            <a href="/"><p>Home</p></a>
            <a href="/about"><p>About</p></a>
            <a href="/blog"><p>Blog</p></a>
            <a href="service"><p>Services</p></a>
           </div>
        </div>
        <div className={`${styles.cInfoDesc} ${styles.otherdescBox}`}>
            <h3>
                Let’s Connect
            </h3>
            <div className={styles.emailBox}>
                <SiGmail style={{
                  color: '#00deff'
                }}/>
                info@codesmash.org
            </div>
        </div>
      </div>
    </div>
  );
};

export default Cinfo;
