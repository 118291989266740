import styles from './header.module.css';
import { elements } from './mission';
import ScrollAnimation from 'react-animate-on-scroll';


const Overview = () => {
    const elementsArray = Object.entries(elements);

    return (
        <div className={`${styles.aboutContainer} ${styles.missionField}`}>
            <div className={styles.aboutFieldWrapper}>
               <ScrollAnimation
               animateIn='fadeIn'
               animateOnce='true'
               >
               <div className={styles.aboutUsHeader}>
                    <h2>Our Mission</h2>
                </div>
               </ScrollAnimation>
                <div className={styles.aboutInfoWrapper}>
                    {elementsArray.map(([key, el]) => {
                        return <div className={styles.infoCards}>
                            <div className={styles.infoCardsImgBox}>
                                <div>
                                    <img className={styles.solutionsImage} src={el.image} alt='' />
                                </div>
                                <div className={styles.sInfoBox}>
                                    <h3>{el.title}</h3>
                                </div>
                                <div className={`${styles.sInfoList} ${styles.storyText}`}>
                                    <p>
                                    {el.desc}
                                    </p>
                                </div>
                            </div>
                        </div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default Overview;