import Header from "../../components/header";
import styles from "./contact.module.css";
import AboutHero from "../about/aboutHero";
import Cblock from "./cBlock";

const Contact = () => {
  return (
    <div className={`${styles.cformContainer}`}>
      <Header />
      <AboutHero
        title='CONTACT US'
        text='Your Path to Tailored Software Solutions' />
      <Cblock />
    </div>
  );
};

export default Contact;
