import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/Home/homePage";
import Contact from './pages/ContactUs/contact'
import ServicePage from "./pages/cService/servicePage";
import AboutPage from "./pages/about/aboutPage";
import BlogPage from "./pages/Blog/blogPage";


const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='' element={<HomePage />}/>
        <Route path='contact-us' element={<Contact />}/>
        <Route path='blog' element={<BlogPage />}/>
        <Route path='about' element={<AboutPage />}/>
        <Route path='service' element={<ServicePage />}/>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
